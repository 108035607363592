import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadFeaturesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadFeaturesSuccess,
    (state, { fiscalPrinterModelId, features }) => {
      const entity = state.entities?.[fiscalPrinterModelId];
      return fromState.featureAdapter.upsertOne(
        { ...entity, id: fiscalPrinterModelId, features },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    },
  ),
  on(fromActions.loadFeaturesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadFieldsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadFieldsSuccess,
    (state, { fiscalPrinterModelId, fields }) => {
      const entity = state.entities?.[fiscalPrinterModelId];
      return fromState.featureAdapter.upsertOne(
        { ...entity, id: fiscalPrinterModelId, fields },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    },
  ),
  on(fromActions.loadFieldsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function fiscalPrinterModelsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
