import { createAction, props } from '@ngrx/store';

import {
  FiscalPrinterModel,
  FiscalPrinterModelFeatures,
  FiscalPrinterModelFields,
} from '../../models';

export const loadRequest = createAction('[Fiscal Printer Models] Load Request');
export const loadSuccess = createAction(
  '[Fiscal Printer Models] Load Success',
  props<{ items: FiscalPrinterModel[] }>(),
);
export const loadFailure = createAction(
  '[Fiscal Printer Models] Load Failure',
  props<{ error: any }>(),
);

export const loadFeaturesRequest = createAction(
  '[Fiscal Printer Models] Load Features Request',
  props<{ fiscalPrinterModelId: number }>(),
);
export const loadFeaturesSuccess = createAction(
  '[Fiscal Printer Models] Load Features Success',
  props<{
    fiscalPrinterModelId: number;
    features: FiscalPrinterModelFeatures;
  }>(),
);
export const loadFeaturesFailure = createAction(
  '[Fiscal Printer Models] Load Features Failure',
  props<{ error: any }>(),
);

export const loadFieldsRequest = createAction(
  '[Fiscal Printer Models] Load Fields Request',
  props<{ fiscalPrinterModelId: number }>(),
);
export const loadFieldsSuccess = createAction(
  '[Fiscal Printer Models] Load Fields Success',
  props<{
    fiscalPrinterModelId: number;
    fields: FiscalPrinterModelFields;
  }>(),
);
export const loadFieldsFailure = createAction(
  '[Fiscal Printer Models] Load Fields Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Fiscal Printer Models] Reset State');
